@import '_general.scss';

.widget_revendedor {
	z-index: 999;
	position: absolute;
	height: 370px;
	top: 40px;
	padding-top: 10px;
	.revendedor {
	    width: 100%;
    	margin: 0 auto;
		h2 {
			font-size: 18px;
			text-transform: uppercase;
			text-align: center;
			margin-top: 20px;
		}
		p {
			font-size: 14px;
			line-height: 18px;
			margin: 20px 0 15px 0;
			text-align: center;
		}
		.login{
			background: #fff;
			width: 100%;
			padding: 10px;
			float: left;
			form{
				.select-div {
					width: 100%;
					height: 40px;
					border: 1px solid #000;
					font-size: 11px;
					margin-top: 5px;
					@include placeholder {
						font-size:11px;
						color: #a4a4a4;
						text-transform: uppercase;
						padding: 5px 0;
					}
				}
				input {
					width: 100%;
					height: 40px;
					border:1px solid #000;
					font-size: 11px;
					padding: 10px;
					margin: 5px 0 5px;
					@include placeholder{
						font-size:11px;
						color: #a4a4a4;
						text-transform: uppercase;
					}
				}
				a {
					font-size: 14px;
					color: #fe0000;
					margin: 10px 0 0 0;
					width: 100%;
					display: inline-block;
				}
	            button {
	                border: 1px solid black;
	                background: transparent;
	                border-radius: 0px;
	                width: 100%;
	                height: 40px;
	                font-size: 11px;text-transform: uppercase;
					margin-top: 20px;
	            }
			}
		}
		.info {
			text-align: center;
			color: #fff;
			opacity: .75;
			width: 100%;
			float: left;
			margin: 20px 0;
			padding: 0 30px;
			h2 {
				margin-bottom: 20px;
			}
			p {
				margin: 0;
				padding: 0 5px;
				&:last-child {
					margin-bottom: 20px;
				}
			}
		}
	}
}

.select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 10px;
}
