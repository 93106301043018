@import 'general';

.modal.modal-vo-default,
.modal {
  .modal-dialog{
    margin-top: 100px;
  }

  .modal-close {
    right: -7px;
    top: -50px;
  }

}

.modal.modal-vo-default {
  .modal-dialog {
    margin-top: 100px;
  }
  .modal-content {
    padding: 70px 30px;
  }
  .item-remove-icon {
    top: 50px;
    right: 10px;
  }
}

#reseller-modal .modal-dialog {
  margin-top: 0;
}