@font-face {
	font-family: 'PE_icons';
	src:url('/fonts/PE_icons.eot?4rllhp');
	src:url('/fonts/PE_icons.eot?4rllhp#iefix') format('embedded-opentype'),
		url('/fonts/PE_icons.ttf?4rllhp') format('truetype'),
		url('/fonts/PE_icons.woff?4rllhp') format('woff'),
		url('/fonts/PE_icons.svg?4rllhp#PE_icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'PE_icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-g:before {
    content: "\67";
}
.icon-G:before {
    content: "\47";
}
.icon-q:before {
    content: "\71";
}
.icon-Q:before {
    content: "\51";
}
.icon-j_peq:before {
	content: "\6a";
}
.icon-J:before {
	content: "\4a";
}
.icon-k_peq:before {
	content: "\6b";
}
.icon-K:before {
	content: "\4b";
}
.icon-n_peq:before {
	content: "\6e";
}
.icon-N:before {
	content: "\4e";
}
.icon-o:before {
	content: "\6f";
}
.icon-O:before {
	content: "\4f";
}
.icon-y:before {
	content: "\79";
}
.icon-Y:before {
	content: "\59";
}
.icon-uni002F:before {
	content: "\2f";
}
.icon-uniE9002:before {
	content: "\3c";
}
.icon-I:before {
	content: "\49";
}
.icon-h_peq:before {
	content: "\68";
}
.icon-uniE900:before {
	content: "\28";
}
.icon-i_peq:before {
	content: "\69";
}
.icon-p_peq:before {
	content: "\70";
}
.icon-P:before {
	content: "\50";
}
.icon-r_peq:before {
	content: "\72";
}
.icon-S:before {
	content: "\53";
}
.icon-X:before {
	content: "\58";
}
.icon-R:before {
	content: "\52";
}
.icon-t_peq:before {
	content: "\74";
}
.icon-T:before {
	content: "\54";
}
.icon-0:before {
	content: "\30";
}
.icon-1:before {
	content: "\31";
}
.icon-2:before {
	content: "\32";
}
.icon-3:before {
	content: "\33";
}
.icon-4:before {
	content: "\34";
}
.icon-5:before {
	content: "\35";
}
.icon-6:before {
	content: "\36";
}
.icon-7:before {
	content: "\37";
}
.icon-8:before {
	content: "\38";
}
.icon-9:before {
	content: "\39";
}
.icon-w_peq:before {
	content: "\77";
}
.icon-W:before {
	content: "\57";
}
.icon--:before {
	content: "\2d";
}
.icon-plus:before {
	content: "\2a";
}
.icon-+:before {
	content: "\2b";
}
.icon->:before {
	content: "\3e";
}
.icon-a:before {
	content: "\61";
}
.icon-A:before {
	content: "\41";
}
.icon-b:before {
	content: "\62";
}
.icon-B:before {
	content: "\42";
}
.icon-c:before {
	content: "\63";
}
.icon-C:before {
	content: "\43";
}
.icon-d:before {
	content: "\64";
}
.icon-D:before {
	content: "\44";
}
.icon-e:before {
	content: "\65";
}
.icon-E:before {
	content: "\45";
}
.icon-f:before {
	content: "\66";
}
.icon-F:before {
	content: "\46";
}
.icon-l:before {
	content: "\6c";
}
.icon-m:before {
	content: "\6d";
}
.icon-M:before {
	content: "\4d";
}
.icon-s:before {
	content: "\73";
}
.icon-u:before {
	content: "\75";
}
.icon-U:before {
	content: "\55";
}
.icon-v:before {
	content: "\76";
}
.icon-V:before {
	content: "\56";
}
.icon-x:before {
	content: "\78";
}
.icon-z:before {
	content: "\7a";
}
.icon-Z:before {
	content: "\5a";
}
