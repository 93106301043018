body{
    background-color: #f7f7f7;
}

.error-container{
    padding-top: 50px;
    padding-bottom: 75px;
    color: #000;
    text-align: center;
    .error-title{
        font-size: 125px;
        font-weight: 300;
    }
    .error-message{
        font-size: 21px;
        line-height: 25px;
        margin-top: 75px;
    }
}

