@import '../../partials/general';
@import '../../partials/mobile/general';
@import "general";

.error-container{
  .error-title{
    font-size: 54px;
  }
  .error-message{
    font-size: 14px;
  }
}