body.lock > header.fixed.search-open nav {
    overflow: hidden;
    height: 50px;
    a.brand,
    ul.navbar-misc {
        position: fixed;
    }
}

#header-search {
    bottom: 0;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    overflow: auto;
    .js-ios-focus-workaround{
        position: fixed;
        top: -100%;
    }
}

.search-form-container {
    background-color: #262626;
    padding: 10px;
}

#search-form {
    border: solid #9d9d9d 1px;
    color: #a4a4a4;
    display: block;
    height: 50px;
    padding: 15px;
    position: relative;
    #clear-search-form{
        background-color: transparent;
        border: none;
        color: #a4a4a4;
        cursor: pointer;
        font-size: 50px;
        position: absolute;
        right: 75px;
        top: -6px;
        display: none;
        &.enabled{
            display: block;
        }
    }
    input{
        background-color: transparent;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        outline: none;
        padding-right: 110px;
        width: 100%;
    }
    button{
        background-color: transparent;
        border: none;
        bottom: 0;
        color: #a4a4a4;
        cursor: pointer;
        font-size: 11px;
        outline: none;
        padding: 15px;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        top: 0;
    }
}

.autocomplete-search-results{
    ul.ui-autocomplete {
        background: rgba(42,42,42,0.96); //fallback
        background: -moz-linear-gradient(top, rgba(38,38,38,1) 0, rgba(38,38,38,0.86) 50px);
        background: -webkit-linear-gradient(top, rgba(38,38,38,1) 0,rgba(38,38,38,0.86) 50px);
        background: linear-gradient(to bottom, rgba(38,38,38,1) 0,rgba(38,38,38,0.86) 50px);
        margin: 0 auto;
        padding: 20px 0;
        position: relative;
        li {
            border-bottom: solid #9c9c9c 1px;
            margin: 0 15px;
            min-height: 45px;
            padding: 5px 15px;

            &:nth-last-child(1) {
                border-bottom: none;
                padding-bottom: 0;
            }

            .title{
                color:#fff;
                font-size: 13px;
                line-height: 18px;
            }

            .category{
                color: #a2a2a2;;
                font-size: 12px;
                font-style: italic;
                line-height: 18px;
            }
        }
    }
}

