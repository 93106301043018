@mixin widget-submenu-color($colorName, $colorValue) {
    &.bckg-#{$colorName} {
        background-color: $colorValue;
    }

    &.color-#{$colorName}{
        nav ul li {
            color: $colorValue;
        }
        a{
            color: $colorValue;
            &:after {
                border-right-color: $colorValue;
            }
        }
    }
}

.sub-menu {
    height: 50px;
    position: relative;
    @include widget-submenu-color('white', #fff);
    @include widget-submenu-color('blue', #fe0000);
    @include widget-submenu-color('gray', #d9d9d9);
    @include widget-submenu-color('darkgray', #9d9d9d);
    z-index: 11;

    &.scroll-to-content-fixed {
        position: fixed;
        & + * {
            margin-top: 50px;
        }
        z-index: 100;
    }

    .sub-menu-container{
        position: relative;
        overflow: hidden;

        .hidden.arrows-container{
            display: none!important;
        }

        .sub-menu-wrapper {
            ul {
                float:right;
                white-space: nowrap;
                li {
                    display: inline-block;
                    font-size: 15px;
                    &:last-child {
                        border-right: 0;
                    }
                    a{
                        display: block;
                        padding: 15px 0px 15px 15px;
                        &:after {
                            border-right-width: 1px;
                            border-right-style: solid;
                            content: '';
                            padding-right: 15px;
                            @include context('li', 'li:last-child') {
                                border: none;
                            }
                        }
                    }
                    &.raw-content{
                        padding: 15px 0px;
                    }
                    &.active {
                        a {
                            color: #000;
                            font-weight: 700;
                        }
                    }
                    &.prefix{
                        border: none;
                        padding-left: 15px;
                    }
                }
            }
        }
    }
    .sub-menu-nav-arrows{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .arrow{
            @include arrow;
            z-index: 99;
        }
        /*.arrow{
            width: 50px;
            top: 50%;
            border-radius: 3px;
            background-color: #000;
            color: #fff;
            opacity: 0.25;
            font-size: 50px;
            position: absolute;
            cursor: pointer;
            z-index: 99;
            height: 61px;
            margin-top: -30px;
            padding: 0px;
            &.left{
                left: 0;
            }
            &.right{
                right: 0;
            }
        }*/
    }
    &.color-white{
        li.active {
            a {
                color: #000;
            }
        }
    }
}

@media only all and (min-width:768px) and (max-width:1199px) {
    .sub-menu {
        .sub-menu-nav-arrows{
            .arrow{
                &.left{
                    margin-left: 5px;
                }
                &.right{
                    margin-right: 5px;
                }
            }
        }
    }
}

@media only all and (min-width:1200px) {
    .sub-menu {
        .sub-menu-nav-arrows{
            .arrow{
                &.left{
                    margin-left: 45px;
                }
                &.right{
                    margin-right: 45px;
                }
            }
        }
    }
}
