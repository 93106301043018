@import 'header-search';
@import 'header-shopping-cart';

body.lock>header {
    bottom: 0;
}

main {
    margin-top: 50px;
}

body {
    &.headerless > main {
        padding-top: 0;
    }
    & > header {
        top: 0;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 99;

        &.fixed{
            position: fixed;
        }

        nav {
            position: relative;
            max-height: 100%;
            background-color: #fff;
            overflow: hidden;

            @include context("body", "body.lock") {
                overflow-y: auto;
            }

            .vo-container{
                @extend %clearfix;
                padding: 0;
            }

            .brand {
                margin-left: 10px;
                z-index: 10;
                position: absolute;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .backg {
                position: absolute;
                top: 0;
                height: 50px;
                left: 0;
                right: 0;
                background-color: #000;
                z-index: -1;
            }

            .overlay{
                display: none;
                background-color: rgba(0, 0, 0, 0.75);
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: -1;
                @include context("body", "body.lock"){
                    display: block;
                }
            }

            img.brand-logo {
                /*width: 84px;*/
                height: 30px;
            }

            .lvl-0, .lvl-1, .lvl-2, .lvl-3 {
                min-height: 50px;
                width: 100%;
                padding-top: 14px;
            }

            .lvl-0, .lvl-1, .lvl-2 {
                border-top: solid #5d5d5d 1px;
                &.open .arrow {
                    color: #fff;
                }
            }

            .lvl-0 {
                font-weight: 600;
                font-size: 15px;
                padding-left: 20px;
                color: #fff;
            }

            .lvl-1 {
                padding-left: 40px;
                color: #fff;
            }

            .lvl-2, .lvl-3 {
                background-color: #3d3d3d;
                padding-left: 60px;
            }

            .links-container {
                width: 100%;
                //min-height: 50px;
                margin-top: 50px;
                @include context('body', 'body.lock') {
                    // margin-top: 50px;
                }

            }

            ul.links {
                list-style: none;
                font-size: 15px;
                background-color: #262626;
                overflow: auto;
                max-height: 100%;
                height: 0;

                & > li {
                    min-height: 50px;
                    position: relative;
                    overflow: hidden;

                    &.menu {
                        min-height: 0;
                        & > .vo-container {
                            padding: 0;
                        }
                    }

                    & > .option {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        & > .name {
                            display: block;
                            vertical-align: middle;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            padding: 14px 20px;
                            line-height: 21px;
                            a{
                                display: block;
                                padding: 15px 0;
                                margin-top: -15px;
                                &:focus {
                                    text-decoration: none;
                                    color: inherit;
                                }
                            }
                        }
                    }
                    & .option {
                        & > .arrow {
                            right: 0;
                            top: 0;
                            font-size: 50px;
                            position: absolute;
                            color: #fff;
                        }
                    }

                }

                .col > li {
                    min-height: 50px;
                    position: relative;

                    & > .option {
                        & > .name {
                            display: inline-block;
                            vertical-align: middle;
                        }

                    }
                }
            }

            .title {
                .option > .name {
                    font-weight: 700;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #fff;
                }
            }

            .subtitle {

                &.open {
                    border-bottom: solid #5d5d5d 1px;
                }

                .option > .name {
                    font-size: 14px;
                    color: #fff;
                }

            }

            .item {

                &.infopedia-item {
                    border-top: solid #5d5d5d 1px;
                }
                .option > .name {
                    font-size: 14px;
                    color: #fff;
                    text-decoration: none;

                    &.orange {
                        color: #fda100;
                    }
                }
            }




            .single-field {
                padding-bottom: 80px;
                border: none;
                & > .field {
                    width: 100%;
                    & > form {
                        position: absolute;
                        left: 20px;
                        right: 15px;
                        height: 50px;
                        display: inline-block;

                        & > input[type=text] {
                            @include placeholder {
                                text-align:center;
                                color: #a4a4a4;
                                text-transform: uppercase;
                            }
                            padding: 0 40px 0 10px;
                            width: 100%;
                            height: 50px;
                            font-size: 11px;
                            color: #a4a4a4;
                            font-weight: 600;
                            border-radius: 0;
                        }
                        & > label {
                            border: none;
                            position: absolute;
                            right: 0;
                            top: 0;
                            height: 50px;
                            width: 30px;
                            float: right;
                            background-color: #fda100;
                            color: #fff;
                            font-size: 50px;
                            & > .icon-B {
                                right: -10px;
                                top: 0;
                                position: absolute;
                                &:before{
                                    content: "\6c";
                                }
                            }
                        }
                    }
                }

            }

body > header nav ul.links .menu.list .cta.single-field label span

            .option {
                .arrow {
                    font-size: 50px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #fff;
                }

                .icon-U {
                    color: #fe0000;
                    font-size: 50px;
                    position: absolute;
                    left: 24px;
                    top: 0;
                }
            }

            ul.navbar-misc {
                z-index: 10;
                position: absolute;
                top: 0;
                right: 0;
                line-height: 50px;
                margin: 7px 0;

                & > li {
                    margin-top: -6px;
                    float: left;
                    height: 50px;
                    color: #959595;
                    font-size: 50px;
                    padding: 0;
                    cursor: pointer;
                    position: relative;

                    &>a, div {
                        text-decoration: none;
                    }
                    &.empty-shopping-cart .total-cart-items{
                        display:none;
                    }
                }

                .total-cart-items{
                    font-size: 12px;
                    font-weight: 700;
                    text-align: center;
                    color: #ffffff;
                    border-radius: 21px;
                    background: #fe0000;
                    width: 21px;
                    height: 21px;
                    line-height: 21px;
                    position: absolute;
                    right: calc(50% - 21px);
                    top: 5px;

                }

            }

            .menu {
                &.author {
                    padding: 20px 10px 35px 10px;
                    .col-1 {
                        float: left;
                        display: inline-block;
                        padding-right: 15px;
                        vertical-align: top;
                        width: 100px;
                        height: 120px;
                    }
                    .col-2 {
                        float: left;
                        display: inline-block;
                        vertical-align: top;
                        width: calc(100% - 100px);
                    }
                    .col-3 {
                        clear: both;
                        padding-top: 25px;
                        vertical-align: bottom;
                        width: 100%;

                        @extend %link-no-effects;
                        a{
                            text-decoration: none;
                        }
                    }

                    h4 {
                        font-size: 14px;
                        font-weight: bold;
                        color: #fe0000;
                        text-transform: uppercase;
                    }

                    h5 {
                        font-size: 18px;
                        line-height: 18px;
                        color: #fe0000;
                        margin-bottom: 15px;
                        margin-top: 15px;
                    }

                    .content {
                        @extend .text-regular;
                        color: #9d9d9d;
                        margin-bottom: 20px;
                    }

                    .more {
                        @extend .text-regular;
                        text-decoration: underline;
                        color: #9d9d9d;
                        text-transform: none;
                    }

                    .author-name-link {
                        text-decoration: none;
                    }

                    .picture {
                        height: 120px;
                        margin-top: 15px;
                        margin-right: 10px;
                        width: 100%;
                        background-size: cover;
                        background-position: center center;
                    }

                    .button {
                        @include button-type-b(#fe0000);
                        height: 40px;
                        width: 100%;
                        font-weight: 600;
                        position: relative;
                        padding: 12px 20px;
                        & > .icon-C {
                            font-size: 50px;
                            position: absolute;
                            top: -4px;
                            right: 0;
                        }
                    }
                }

                &.layout-c {
                    padding-left: 20px;
                    h4 {
                        font-weight: 700;
                        font-size: 14px;
                        text-transform: uppercase;
                        color: #fe0000;
                        margin-top: 20px;
                    }

                    h5 {
                        font-size: 18px;
                        line-height: 18px;
                        margin-bottom: 15px;
                        color: #fe0000;
                    }

                    .col-1 {
                        padding-top: 15px;
                        display: inline-block;
                        width: 35%;
                        margin-right: 10px;
                        img {
                            width: 100%;
                        }
                    }

                    .col-2 {
                        display: inline-block;
                        width: 60%;
                        vertical-align: top;
                        padding-top: 15px;

                        .content, a {
                            font-size: 14px;
                            text-align: left;
                            line-height: 18px;
                            color: #9d9d9d;
                        }

                        .content {
                            margin-bottom: 10px;
                        }

                    }

                    .col-3 {
                        margin: 25px 20px 30px 0;

                        button {
                            position: relative;
                            width: 100%;
                            height: 40px;
                            border: none;
                            background-color: #fe0000;
                            font-weight: 600;
                            font-size: 11px;
                            text-align: center;
                            text-transform: uppercase;
                            text-align: center;
                            color: #fff;
                            margin-right: 20px;
                            font-weight: 600;
                            & > .icon-C {
                                font-size: 50px;
                                position: absolute;
                                top: -4px;
                                right: 0;
                            }

                        }
                    }

                    & > .vo-container {
                        padding: 0 4px;
                    }
                }
            }
        }
    }
}

body > header nav ul.navbar-misc.open-modal-reseller-fixed {
    position: fixed;
}

@media all and (max-width: 319px) {
    header nav{
        .lvl-0>.option>.name{
            padding-left: 10px;
        }

        .lvl-1{
            &>.option>.name{
                padding-left: 20px;
            }
        }

        .lvl-2, .lvl-3{
            &>.option>.name{
                padding-left: 30px;
            }
        }

        .option{
            .icon-U {
                left: 0;
            }
        }
    }
}
